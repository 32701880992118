"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-9d98cf72"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = { class: "scroll-num-item" };
const vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    props: {
        num: {
            type: Number,
            default: 0
        },
        duration: {
            type: Number,
            default: 3000
        }
    },
    setup(__props) {
        const props = __props;
        const numForDisplay = (0, vue_3.ref)(0);
        let timer = -1;
        const limitMinNum = 30;
        function startAnimation() {
            const { num, duration } = props;
            const totalNum = limitMinNum + num;
            numForDisplay.value = 0;
            clearInterval(timer);
            timer = setInterval(() => {
                if (numForDisplay.value >= totalNum) {
                    clearInterval(timer);
                }
                else {
                    numForDisplay.value++;
                }
            }, duration / totalNum);
        }
        (0, vue_3.watch)(() => props.num, () => {
            startAnimation();
        }, { immediate: true });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, (0, vue_2.toDisplayString)(numForDisplay.value % 10), 1));
        };
    }
});
