"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    props: {
        size: {
            type: String,
            default: 'medium'
        },
        on: {
            type: Boolean,
            default: false
        }
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                class: (0, vue_2.normalizeClass)(["common-button", { 'medium': __props.size === 'medium', 'small': __props.size === 'small', on: __props.on }])
            }, [
                (0, vue_2.renderSlot)(_ctx.$slots, "default")
            ], 2));
        };
    }
});
