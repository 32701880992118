"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-7a0b2008"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = { class: "common-data-block" };
const _hoisted_2 = { class: "common-data-block-inner" };
const _hoisted_3 = { class: "block-num" };
const _hoisted_4 = { class: "block-title" };
exports.default = (0, vue_1.defineComponent)({
    props: {
        num: {
            type: [Number, String],
            default: 0
        },
        title: {
            type: String,
            default: ''
        }
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("div", _hoisted_3, (0, vue_2.toDisplayString)(__props.num), 1),
                    (0, vue_2.createElementVNode)("div", _hoisted_4, (0, vue_2.toDisplayString)(__props.title), 1)
                ])
            ]));
        };
    }
});
